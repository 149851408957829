import "../styles/layouts/404.scss";

import { Link } from "gatsby";
import * as React from "react";

import Layout from "../components/Layout/layout";
import Tweets from "../components/Slider/Twitter/Tweets";

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <div className="o-hero-404">
        <div className="container">
          <h1 className={"a-title"}>Error 404</h1>
          <p>
            <pre>Página no encontrada</pre>
          </p>
        </div>
      </div>
      <main className={"container o-404"}>
        <p>
          Páginas disponibles:
          <ul>
            <li>
              <Link to={"/"}>Inicio</Link>
            </li>
            <li>
              <Link to={"/consejos-carretera-ciclistas-conductores/"}>
                Consejos para ciclistas al circular en carretera con conductores
              </Link>
            </li>
          </ul>
        </p>
      </main>
      <Tweets />
    </Layout>
  );
};

export default NotFoundPage;
